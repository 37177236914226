import React, { useEffect, useContext } from 'react'
import { Parallax, ParallaxLayer } from 'react-spring/renderprops-addons'
import { config } from 'react-spring'
import { CTX } from 'context/Store'
import ContactSection from 'components/ContactSection/ContactSection'
import ProjectsSection from 'components/ProjectsSection/ProjectsSection'
import './ParallaxPage.scss'

const ParallaxPage = () => {
  const [appState] = useContext(CTX)
  const { page } = appState

  let parallax

  useEffect(() => {
    parallax.scrollTo(page)
  }, [page])

  return (
    <div className='parallax-container'>
      <Parallax
        className='parallax-main'
        ref={(ref) => (parallax = ref)}
        pages={4}
        config={config.wobbly}>
        <ParallaxLayer offset={0} speed={0}>
          <div className='parallax-image-container'>
            <div className='parallax-image parallax-image1' />
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={0.4} speed={-4.2}>
          <div className='me' />
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={1}>
          <div className='parallax-inner'>
            <div className='title'>THOMAS FOYDEL</div>
            <div className='parallax-inner-text'>
              <a href='mailto: thomasjfoydel@gmail.com'>
                <p className='email'>thomasjfoydel@gmail.com</p>
              </a>
              <p className='bio'>A web developer from Chicago</p>
            </div>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={1} speed={0}>
          <div className='parallax-image-container'>
            <div className='parallax-image parallax-image2' />
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={0} speed={1} className='parallax-textlayer'>
          <div className='infobox'>
            <h2>"Always bet on JavaScript"</h2>
            <p>Brendan Eich</p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={1}>
          <div className='parallax-inner'>
            <ProjectsSection />
            {/* <div className='prarallax-order-button'>some projects</div> */}
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2} speed={0}>
          <div className='parallax-image-container'>
            <div className='parallax-image parallax-image3' />
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={1} speed={1} className='parallax-textlayer'>
          <div className='infobox'>
            <h2>Imagine how cool</h2>
            <p>
              it would be if you hired me. You seem cool. I'm pretty cool
              myself. Let's be cool together.
            </p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={2} speed={1}>
          <div className='parallax-inner'>
            <div className='title'>
              LONG
              <br />
              STORY
              <br />
              SHORT
            </div>
            <p className='parallax-inner-text'>
              I am a software engineer.
              <br /> I am hungry and driven.
            </p>
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={3} speed={0}>
          <div className='parallax-image-container'>
            <div className='parallax-image parallax-image4' />
          </div>
        </ParallaxLayer>

        <ParallaxLayer offset={2} speed={1} className='parallax-textlayer'>
          <div className='infobox'>
            <h2>Long Story Long</h2>
            <p>
              I have worked as a fullstack engineer for Rainmaker Games and as a
              Web3 engineer for WiseSoft, LLC. I have professional experience
              building with JavaScript, TypeScript, React, Web3/Ethers, Node,
              Express, Nest, Next, Prisma, CockroachDB, FireBase, Contentful,
              Vercel, and AWS. Previously I worked for 2U and Northwestern
              University's coding bootcamps. Before tech, I worked in applied
              behavior analysis (autism therapy). Some of my favorite tools I've
              built with include web sockets, webRTC, the Web Audio API, The
              Graph, IPFS, and the react-spring animation library. I strengthen
              my computer science fundamentals with arduino, raspberry pi, and
              6502 projects created in C++ and assembly. I have a Bachelor's
              degree in psychology and sociology from Roosevelt University. In
              my spare time I train jiu-jitsu and boxing.
            </p>
          </div>
        </ParallaxLayer>
        <ParallaxLayer offset={3} speed={1}>
          <div className='parallax-inner'>
            <ContactSection />
          </div>
        </ParallaxLayer>
        {/* 
        {showAuth && (
          // <ParallaxLayer offset={0} speed={0}>
          // <div className='parallax-inner'>
          <AuthPortal />
          // </div>
          // </ParallaxLayer>
        )} */}

        {/* <ParallaxLayer offset={3} speed={1} className='parallax-textlayer'>
          <div className='infobox'>
            <h2>Perspiciatis dignissimos</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
              exercitationem temporibus totam iusto possimus accusamus, at
              corrupti dolorum atque sequi hic nobis sint repellat eum
              aspernatur dignissimos quia molestiae? Officiis, quibusdam
              architecto labore ab modi porro, eius velit placeat saepe
              doloribus exercitationem similique odit quod facilis consequatur.
              Eaque, temporibus aperiam repudiandae libero consequuntur saepe
              dicta nemo quidem impedit. Laborum, officia beatae dolore velit!
            </p>
          </div>
        </ParallaxLayer> */}

        {/* <ParallaxLayer offset={4} speed={0.3}>
          <div className='parallax-image-container'>
            <div className='parallax-image parallax-image5' />
          </div>
        </ParallaxLayer> */}
        {/* <ParallaxLayer offset={3} speed={1} className='parallax-textlayer'>
          <div className='infobox'>
            <h2>Perspiciatis dignissimos</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
              exercitationem temporibus totam iusto possimus accusamus, at
              corrupti dolorum atque sequi hic nobis sint repellat eum
              aspernatur dignissimos quia molestiae? Officiis, quibusdam
              architecto labore ab modi porro, eius velit placeat saepe
              doloribus exercitationem similique odit quod facilis consequatur.
              Eaque, temporibus aperiam repudiandae libero consequuntur saepe
              dicta nemo quidem impedit. Laborum, officia beatae dolore velit!
            </p>
          </div>
        </ParallaxLayer> */}
        {/* <ParallaxLayer offset={4} speed={1}>
          <div className='parallax-inner'>CONTACT</div>
        </ParallaxLayer> */}

        {/* <ParallaxLayer offset={4} speed={1}>
          <div className='infobox'>
            <h2>Perspiciatis dignissimos</h2>
            <p>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Soluta
              exercitationem temporibus totam iusto possimus accusamus, at
              corrupti dolorum atque sequi hic nobis sint repellat eum
              aspernatur dignissimos quia molestiae? Officiis, quibusdam
              architecto labore ab modi porro, eius velit placeat saepe
              doloribus exercitationem similique odit quod facilis consequatur.
              Eaque, temporibus aperiam repudiandae libero consequuntur saepe
              dicta nemo quidem impedit. Laborum, officia beatae dolore velit!
            </p>
          </div>
        </ParallaxLayer> */}
      </Parallax>
    </div>
  )
}

export default ParallaxPage
