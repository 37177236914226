import React, { useState, useEffect, useContext, useRef } from 'react'
import axios from 'axios'
import { CTX } from 'context/Store'
import github from 'imgs/socials/github.png'
import youtube from 'imgs/socials/youtube.png'
import linkedin from 'imgs/socials/linkedin.png'
import bounce from 'imgs/loading/bounce.gif'
import AuthPortal from 'components/AuthPortal/AuthPortal'

import './ContactSection.scss'

const ContactSection = () => {
  const [formData, setFormData] = useState({ name: '', email: '', message: '' })
  const { message, name, email } = formData
  const [{ showAuth }, updateState] = useContext(CTX)
  const [sending, setSending] = useState(false)
  const [sendSuccess, setSendSuccess] = useState(false)
  const [err, setErr] = useState('')

  const handleChange = ({ target }) => {
    const { value, id } = target
    setFormData((f) => ({ ...f, [id]: value }))
  }

  const handleClick = () => updateState({ type: 'INCREMENT' })

  const sendMessage = (e) => {
    e.preventDefault()
    if (!email || !name || !message) return setErr('all fields required')

    const re =
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    if (!re.test(String(email).toLowerCase())) {
      return setErr('must contain a valid email address')
    }

    setSending(true)

    axios
      .post('/api/message', formData)
      .then(({ data }) => {
        const { err } = data
        setTimeout(() => {
          if (err) setErr(String(err))
          else setSendSuccess(true)
          setSending(false)
        }, 1000)
      })
      .catch((err) => {
        setErr(String(err))
        setSending(false)
      })
    setFormData({ name: '', email: '', message: '' })
  }

  const firstRender = useRef(false)
  useEffect(() => {
    let subscribed = true
    firstRender.current
      ? (firstRender.current = true)
      : setTimeout(() => subscribed && setErr(''), 2250)
    return () => (subscribed = false)
  }, [err])

  return (
    <div className='contact-section'>
      <div className='form-section'>
        <form className='form' onSubmit={sendMessage}>
          <label htmlFor='name'>Name</label>
          <input
            name='name'
            value={name}
            type='text'
            id='name'
            onChange={handleChange}
            maxLength='90'
          />
          <label htmlFor='email'>E-mail</label>
          <input
            name='email'
            value={email}
            type='email'
            id='email'
            onChange={handleChange}
            maxLength='90'
          />
          <label htmlFor='message'>Message</label>
          <textarea
            name='message'
            value={message}
            id='message'
            onChange={handleChange}
            maxLength='500'
          />
          <div className='bottom-section'>
            {sending ? (
              <img src={bounce} className='loading' alt='sending...' />
            ) : (
              <>
                {sendSuccess ? (
                  <div className='thanks'>
                    <p>thank you for your inquiry!</p>
                    <button type='button' onClick={() => setSendSuccess(false)}>
                      send another
                    </button>
                  </div>
                ) : (
                  <button type='submit'>send</button>
                )}
              </>
            )}
            <p className='err'> {err}</p>
          </div>
        </form>
        <div
          onClick={handleClick}
          style={{
            height: '1.5rem',
            width: '1.5rem',
            position: 'absolute',
            left: 0,
            bottom: 0
          }}
        />
        {showAuth && <AuthPortal />}
      </div>
      <div className='icons'>
        <a
          href='https://github.com/thomasfoydel'
          target='_blank'
          rel='noopener noreferrer'>
          <img className='icon github' src={github} alt='github logo' />
        </a>
        <a
          href='https://www.linkedin.com/in/thomasfoydel/'
          target='_blank'
          rel='noopener noreferrer'
          alt='linkedin logo'>
          <img className='icon linkedin' src={linkedin} alt='linkedin logo' />
        </a>
        <a
          href='https://www.youtube.com/channel/UCqXV2F5G9Bv0HWVUDAKApYA'
          target='_blank'
          rel='noopener noreferrer'>
          <img className='icon youtube' src={youtube} alt='youtube logo' />
        </a>
      </div>
    </div>
  )
}

export default ContactSection
