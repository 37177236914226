import React, { useEffect } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import ParallaxPage from 'components/parallaxpage/ParallaxPage'
import AdminPanel from 'components/AdminPanel/AdminPanel'
import linkedin from 'imgs/socials/linkedin.png'
import youtube from 'imgs/socials/youtube.png'
import NavBar from 'components/navbar/NavBar'
import github from 'imgs/socials/github.png'
import { images, gifs } from 'imgs/projects'
import bounce from 'imgs/loading/bounce.gif'
import Store from 'context/Store'
import './App.scss'

function App() {
  useEffect(() => {
    const cacheImages = async () => {
      const imageSrcs = [
        github,
        youtube,
        linkedin,
        bounce,
        ...Object.values(images),
        ...Object.values(gifs)
      ]
      const promises = imageSrcs.map(
        (src) =>
          new Promise((resolve, reject) => {
            const img = new Image()
            img.src = src
            img.onload = resolve()
            img.onerror = reject()
          })
      )
      await Promise.all(promises)
    }
    cacheImages()
  }, [])

  return (
    <Store>
      <div className='App'>
        <Router>
          <Routes>
            <Route path='/admin' element={<AdminPanel />} />
            <Route
              path='/'
              element={
                <>
                  <NavBar />
                  <ParallaxPage />
                </>
              }
            />
          </Routes>
        </Router>
      </div>
    </Store>
  )
}

export default App
