import React, { useState } from 'react'
import axios from 'axios'
import { Navigate } from 'react-router-dom'
import './AuthPortal.scss'
const AuthPortal = () => {
  const [val, setVal] = useState('')
  const [redirect, setRedirect] = useState(false)

  const submit = () => {
    axios.post('/api/auth/admin', { val }).then(({ data: { token, err } }) => {
      if (err) return err
      if (token) {
        localStorage.setItem('thomasfoydeldotcomtoken', token)
        setRedirect(true)
      }
    })
  }

  const handleChange = ({ target: { value } }) => setVal(value)
  const handleKeyDown = ({ key }) => key === 'Enter' && submit()
  return (
    <div className='authportal'>
      {redirect && <Navigate to='/admin' replace />}
      <input
        type='password'
        value={val}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
      />
      <button onClick={submit}></button>
    </div>
  )
}

export default AuthPortal
