import axios from 'axios'
import { Link } from 'react-router-dom'
import React, { useState, useEffect } from 'react'
import { Navigate } from 'react-router-dom'
import './AdminPanel.scss'

const AdminPanel = () => {
  const [messages, setMessages] = useState([])
  const [, setErr] = useState('')
  const [redirect, setRedirect] = useState(false)

  useEffect(() => {
    const synth = window.speechSynthesis
    const speech = new SpeechSynthesisUtterance('Welcome, Thomas')
    synth.speak(speech)
  }, [])

  useEffect(() => {
    const token = localStorage.getItem('thomasfoydeldotcomtoken')
    if (token) {
      axios
        .get('/api/message', { headers: { 'x-auth-token': token } })
        .then(({ data: { messages } }) => {
          if (messages) setMessages(messages)
        })
        .catch((err) => setErr(err))
    } else {
      setRedirect(true)
    }
  }, [])

  const handleSignOut = () => {
    localStorage.clear()
    setRedirect(true)
  }

  return (
    <div className='admin-panel'>
      <h2>admin panel</h2>
      <div className='links'>
        <Link to='/'>back</Link><span onClick={handleSignOut}>sign out</span>
      </div>
      {messages.map((message) => (
        <Message props={{ message, setMessages }} key={message._id} />
      ))}
      {redirect && <Navigate to='/' replace />}
    </div>
  )
}

const Message = ({
  props: {
    message: { name, email, message, _id, createdAt },
    setMessages
  }
}) => {
  const handleDelete = () => {
    const token = localStorage.getItem('thomasfoydeldotcomtoken')
    if (token)
      axios
        .delete(`/api/message/${_id}`, {
          headers: { 'x-auth-token': token }
        })
        .then(({ data: { messages } }) => {
          setMessages(messages)
        })
        .catch((err) => console.log('delete err: ', err))
  }
  const date = new Date(createdAt)

  return (
    <div className='msg'>
      <p className='name'>
        <strong>name:</strong> {name}
      </p>
      <p className='time'>
        {`${date.toDateString()} ${date.toLocaleTimeString()}`}
      </p>
      <p className='email'>
        <strong>email:</strong> {email}
      </p>

      <p className='content'>
        <strong>message:</strong> {message}
      </p>
      <button className='delete' onClick={handleDelete}>
        X
      </button>
    </div>
  )
}

export default AdminPanel
