import { images, gifs } from 'imgs/projects'

const projects = [
  {
    title: 'Commissioner',
    still: {
      src: images['commissioner'],
      alt: 'screenshot of a command line themed user interface'
    },
    gif: {
      src: gifs['commissioner'],
      alt: 'screen recording of a command line themed user interface'
    },
    description:
      'Decentralized and censorship-resistant written content commissioning',
    repo: 'https://github.com/ThomasFoydel/commissioner',
    app: 'https://commissioner.vercel.app/'
  },
  {
    title: 'Roshambo',
    still: {
      src: images['roshambo'],
      alt: 'Two men playing a rock paper scissors game via videochat'
    },
    gif: {
      src: gifs['roshambo'],
      alt: 'Two men playing a rock paper scissors game via videochat'
    },
    description: 'AI videochat rock-paper-scissors with PeerJS and TensorFlow',
    repo: 'https://github.com/thomasfoydel/roshambo',
    app: 'https://roshambo.xyz/'
  },
  {
    title: 'Fitra',
    still: {
      src: images['fitra'],
      alt: 'screenshot of search results displaying multiple trainers'
    },
    gif: {
      src: gifs['fitra'],
      alt: 'navigating through the scheduling process and opening up a video chat'
    },
    description: 'A videochat and scheduling application for personal trainers',
    repo: 'https://github.com/ThomasFoydel/fitra',
    app: 'https://fitra.xyz/'
  },
  {
    title: 'Clean Break',
    still: {
      src: images['cleanbreak'],
      alt: 'screenshot of a step sequencer with various buttons and faders'
    },
    gif: {
      src: gifs['cleanbreak'],
      alt: 'screenshot of a step sequencer with various buttons and faders'
    },
    description: 'A drum machine built with React/Node/Next/Tone',
    repo: 'https://github.com/ThomasFoydel/cleanbreak',
    app: 'https://cleanbreak.vercel.app/'
  },
  {
    title: 'FM Synth',
    still: {
      src: images['fmsynth'],
      alt: 'screenshot of a rotating cube with synthesizer controls on the sides'
    },
    gif: {
      src: gifs['fmsynth'],
      alt: 'screen recording of a rotating cube with synthesizer controls on the sides'
    },
    description:
      'A frequency modulation synthesizer built with React/Node/Next/Tone',
    repo: 'https://github.com/ThomasFoydel/fmsynth',
    app: 'https://fmsynth.vercel.app/'
  },
  {
    title: 'Pandatron',
    still: {
      src: images['pandatron'],
      alt: 'screenshot of an application with knobs, faders, and a keyboard'
    },
    gif: {
      src: gifs['pandatron'],
      alt: 'screen recording of an application with knobs, faders, and a keyboard'
    },
    description: 'A synthesizer built with React and the Web Audio API',
    repo: 'https://github.com/ThomasFoydel/pandatron',
    app: 'https://ThomasFoydel.github.io/pandatron'
  }
  // {
  //   title: 'Svelte Snake',
  //   still: {
  //     src: images['sveltesnake'],
  //     alt: 'screenshot of a snake game.'
  //   },
  //   gif: {
  //     src: gifs['sveltesnake'],
  //     alt: 'a snake sliding across the page eating little pieces of food .'
  //   },
  //   description: 'A snake game built with Node, MongoDB, and Svelte',
  //   repo: 'https://github.com/ThomasFoydel/svelte_snake',
  //   app: 'https://sveltesnake.herokuapp.com/'
  // },
  // {
  //   title: 'Social Media',
  //   still: {
  //     src: images['socialmedia'],
  //     alt: 'screenshot of a social media profile'
  //   },
  //   gif: {
  //     src: gifs['socialmedia'],
  //     alt: 'screenshot of a social media profile'
  //   },
  //   description:
  //     'A social media application built in MERN stack with web sockets',
  //   repo: 'https://github.com/ThomasFoydel/socialmedia',
  //   app: 'http://mernsocialmedia.xyz/'
  // },
  // {
  //   title: 'Socket Chat',
  //   still: {
  //     src: images['socketchat'],
  //     alt: 'screenshot of a chatroom application'
  //   },
  //   gif: {
  //     src: gifs['socketchat'],
  //     alt: 'screenshot of a chatroom application'
  //   },
  //   description: 'A chat application built in MERN stack with web sockets',
  //   repo: 'https://github.com/ThomasFoydel/chat',
  //   app: 'http://socketchat.xyz/'
  // },
  // {
  //   title: 'RnD Schedule',
  //   still: {
  //     src: images['reactrndschedule'],
  //     alt: 'screenshot of a seven day schedule broken into half hours'
  //   },
  //   gif: {
  //     src: gifs['reactrndschedule'],
  //     alt: 'screenshot of a seven day schedule broken into half hours'
  //   },
  //   description: 'A draggable, resizable weekly schedule built with react',
  //   repo: 'https://github.com/ThomasFoydel/react-schedule',
  //   app: 'https://react-rnd-schedule.herokuapp.com/'
  // }
  //   {
  //     title: 'PokedexQL',
  //     src: images['pokedex'],
  //     alt: 'screenshot of a pokedex on top of a list of pokemon',
  //     description: 'A pokedex application built with react and graphQL.',
  //     repo: 'https://github.com/ThomasFoydel/pokedex',
  //     app: 'https://pokedex-gql.herokuapp.com/',
  //   },
]

export default projects
