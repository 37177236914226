import commissioner from './commissioner.png'
import roshambo from './roshambo.png'
import fitra from './fitra.png'
import cleanbreak from './cleanbreak.png'
import fmsynth from './fmsynth.png'
import pandatron from './pandatron.png'
// import socialmedia from './socialmedia.png';
// import socketchat from './socketchat.png';
// import pokedex from './pokedex.png';
// import sveltesnake from './sveltesnake.png';
// import reactrndschedule from './reactrndschedule.png';

import commissionerGif from './gifs/commissioner.gif'
import roshamboGif from './gifs/roshambo.gif'
import cleanbreakGif from './gifs/cleanbreak.gif'
import fitraGif from './gifs/fitra.gif'
import fmsynthGif from './gifs/fmsynth.gif'
import pandatronGif from './gifs/pandatron.gif'
// import reactrndscheduleGif from './gifs/reactrndschedule.gif';
// import socialmediaGif from './gifs/socialmedia.gif';
// import socketchatGif from './gifs/socketchat.gif';
// import sveltesnakeGif from './gifs/sveltesnake.gif';

export const gifs = {
  commissioner: commissionerGif,
  roshambo: roshamboGif,
  cleanbreak: cleanbreakGif,
  fitra: fitraGif,
  fmsynth: fmsynthGif,
  pandatron: pandatronGif
  // reactrndschedule: reactrndscheduleGif,
  // socialmedia: socialmediaGif,
  // sveltesnake: sveltesnakeGif,
  // socketchat: socketchatGif,
}
export const images = {
  commissioner,
  roshambo,
  fitra,
  cleanbreak,
  fmsynth,
  pandatron
  // reactrndschedule,
  // socialmedia,
  // socketchat,
  // pokedex,
  // sveltesnake,
}
