import React, { useState, useContext } from 'react'
import { useSpring, animated } from 'react-spring'
import { CTX } from 'context/Store'

import './NavBar.scss'

const NavBar = () => {
  const [sidebarOpen, setSidebaropen] = useState(false)
  const [appState, updateState] = useContext(CTX)
  const handleMenuOpenClick = () => setSidebaropen(!sidebarOpen)

  const sidebarAnimationProps = useSpring({
    top: '5.2rem',
    opacity: sidebarOpen ? 1 : 0,
    left: sidebarOpen ? 0 : -250
  })

  const changePage = (page) => {
    if (appState.page === page) {
      updateState({
        type: 'CHANGE_PAGE',
        payload: { page: appState.page - 1 }
      })
      setTimeout(() => {
        updateState({ type: 'CHANGE_PAGE', payload: { page } })
      }, 100)
    } else {
      updateState({
        type: 'CHANGE_PAGE',
        payload: { page }
      })
    }
    setSidebaropen(false)
  }

  const mobileChangePage = (page) => {
    setSidebaropen(false)
    setTimeout(() => {
      changePage(page)
    }, 200)
  }

  return (
    <div>
      <div className='navbar-fixed'>
        <div className='navbar'>
          <div className='nav-wrapper'>
            <div className='name-logo' onClick={() => changePage(0)}>
              Thomas Foydel
            </div>
            <div data-target='mobile-demo' className='sidenav-trigger'>
              <i
                className='fa fa-bars fa-2x navbar-opensidebar hide-on-large'
                onClick={handleMenuOpenClick}
              />
            </div>
            <ul className='navbar-large hide-on-med-and-down'>
              <li onClick={() => changePage(1)}>Projects</li>

              <li onClick={() => changePage(2.5)}>About</li>

              <li onClick={() => changePage(3)}>Contact</li>

              <li>
                <a href='/assets/Thomas Foydel Resume.pdf' download>
                  Resume
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <animated.div
        style={sidebarAnimationProps}
        className='sidebarmenu hide-on-large'>
        <div onClick={() => mobileChangePage(1)}>Portfolio</div>
        <hr className='sidebarmenu-linebreak' />
        <div onClick={() => mobileChangePage(2.5)}>About</div>
        <hr className='sidebarmenu-linebreak' />
        <div onClick={() => mobileChangePage(3)}>Contact</div>
        <hr className='sidebarmenu-linebreak' />
        <div>
          <a
            onClick={() => setSidebaropen(false)}
            href='/assets/Thomas Foydel Resume.pdf'
            download>
            Resume
          </a>
        </div>
        <hr className='sidebarmenu-linebreak' />
      </animated.div>
    </div>
  )
}

export default NavBar
