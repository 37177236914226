import React, { useState } from 'react'
import projects from './projects'
import './ProjectsSection.scss'

const ProjectsSection = () => {
  const [currentProjectIdx, setCurrentProjectIdx] = useState(0)
  const currentProject = projects[currentProjectIdx]

  const setIdIfValid = (id) => {
    if (id) setCurrentProjectIdx(+id)
  }
  const handleMouseOver = ({ target: { id } }) => setIdIfValid(id)

  const handleMouseEnter = ({ target: { id } }) => setIdIfValid(id)

  const handleClick = ({ target: { id } }) => setIdIfValid(id)

  return (
    <div className='projects-section'>
      <div
        className='current-project'
        style={{
          backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.707), rgba(0, 0, 0, 0.825)), url(${currentProject.still.src})`
        }}>
        <div className='project-title'>{currentProject.title}</div>
        <img src={currentProject.gif.src} alt={currentProject.gif.alt} />

        <p className='description'>{currentProject.description}</p>
        <div className='links'>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={currentProject.repo}
            className='link repo'>
            repo
          </a>
          <a
            target='_blank'
            rel='noopener noreferrer'
            href={currentProject.app}
            className='link app'>
            live app
          </a>
        </div>
      </div>

      <div className='project-selector'>
        {projects.map((p, i) => (
          <a
            href={p.repo}
            target='_blank'
            rel='noopener noreferrer'
            key={p.title}>
            <div
              key={p.title}
              className={`repo project current-${i === currentProjectIdx}`}
              onMouseOver={handleMouseOver}
              onMouseEnter={handleMouseEnter}
              id={i}
              onClick={handleClick}>
              <img src={p.still.src} alt={p.still.alt} className='img' id={i} />
            </div>
          </a>
        ))}
      </div>
    </div>
  )
}

export default ProjectsSection
