import React from 'react'

const CTX = React.createContext()

export { CTX }

export function reducer(state, action) {
  const { page } = action.payload || {}

  switch (action.type) {
    case 'CHANGE_PAGE':
      return {
        ...state,
        page
      }
    case 'INCREMENT':
      return {
        ...state,
        clicks: state.clicks + 1,
        showAuth: state.clicks + 1 > 10
      }
    default:
      throw Error('reducer error. action: ', action)
  }
}

export default function Store(props) {
  const stateHook = React.useReducer(reducer, {
    page: 0,
    showAuth: false,
    clicks: 0
  })

  return <CTX.Provider value={stateHook}>{props.children}</CTX.Provider>
}
